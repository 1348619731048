/* -- TABLES -- */

table {
    font-size: inherit;
}

/* redefine pure css style */
.pure-table {
    border: 0;

    thead {
        background: none;
    }

    th {
        color: var(--text-base);
    }

    td,
    th {
        vertical-align: middle;
        border-bottom: 1px solid var(--divider-color);
        border-left: 0;
        padding: 0.8em 1em;
        line-height: 1.285;

        &.fit-content {
            width: 1%;
            white-space: nowrap;
        }
    }

    tbody {
        th {
            color: var(--text-base);
            font-weight: bold;
            text-align: left;
            text-transform: none;
        }

        tr {
            &:hover td,
            &:hover th {
                background-color: #fbfbfb;
            }
        }

        & + thead {
            tr {
                th,
                td {
                    padding-top: 20px;
                }
            }
        }
    }

    &.pure-table-horizontal tbody > tr:last-child > td,
    &.pure-table-horizontal tbody > tr:last-child > th {
        border-bottom-width: 1px;
    }

    &.pure-table-horizontal tbody:last-child > tr:last-child > td,
    &.pure-table-horizontal tbody:last-child > tr:last-child > th {
        border-bottom: 0;
    }

    & + & {
        margin-top: 20px;
    }
}

.pure-table-horizontal--s {
    td,
    th {
        padding: 8px 6px;
    }
}
