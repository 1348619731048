h1,
h2,
h3 {
    font-weight: var(--ngw-head-font-weight);
    line-height: 1.1;
    font-family: var(--ngw-head-font-family);
}

h1 {
    color: var(--text-base);
    font-size: 24px;
}

h2 {
    margin-top: 48px;
    margin-bottom: 24px;
    border-bottom: 0;
    padding-bottom: 0;
    color: var(--text-base);
    font-size: 18px;

    &:first-child {
        margin-top: 0;
    }
}

strong,
b,
th {
    font-weight: bold;
}

.heading {
    margin-top: 20px;
    margin-bottom: 12px;
    color: var(--text-secondary);
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
}
